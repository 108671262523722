/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-console */
import { useContext, useEffect, useRef, useState } from 'react';
import { Col, Row, Button, Modal, Typography, Space, notification, Spin, Table } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import './FindScans.scss';
import { createPortal } from 'react-dom';
import dayjs from 'dayjs';
import { DownOutlined, UpOutlined } from '@ant-design/icons';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import StyledCollapse from '../../components/styled/StyledCollapse';
import HeaderPanel from '../../components/styled/HeaderPanel';
import BoundingBoxOverlay from '../../components/ImageAnnotation';
import { Record } from '../../interfaces/Record';
import { AppDispatch, RootState } from '../../store/store';
import scansApiService, { DeleteScansParams, SearchScansPaginationKey, SearchScansResponseData } from '../../api/scansApi.service';
import { deleteScans, resetList, resetReducerAction, resetScansError, resetScansStore, saveScan, searchScans, setFindScansCurrentPage, setFullPdfPresignedURL, setScanPresignedURL, setSorter, submitScan } from '../../store/scans/scansReducer';
import { fetchFieldsByScanId, fetchFullFormByScanId, getUpdatedScan, highlightField, resetFields, resetFieldsError, setDisableAccValidation, setErrorFields } from '../../store/fields/fieldsReducer';
import { Annotation } from '../../components/ImageAnnotation/types';
import StyledSpaceWithTopMargin from '../../components/styled/StyledSpaceWithTopMargin';
import SearchForm from './components/SearchForm';
import LOCALIZATION from '../../localization';
import FieldsTable from './components/FieldsTable';
import ScansTable from './components/ScansTable';
import UnsavedChangesContext from '../../context/UnsavedChanges';
import { resetFormTypesError } from '../../store/formTypes/formTypesReducer';
import { ScannedFieldResponseData } from '../../api/fieldsApi.service';
import AccessDeniedContext from '../../context/AccessDenied';
import PageContainer from '../../components/styled/PageContainer';
import ReportDropdown from './components/ReportDropdown';
import CustomModal from '../../components/CustomModal/customModal';
import DraggableDeleteFormsModal from './components/DraggableDeleteFormsModal';

dayjs.extend(timezone);
dayjs.extend(utc);

/**
 * FindScans is a page for searching and reviewing existing scans.
 * Users can search for scans filtered by form type, date and status.
 * 
 * FindScans component has the most of logic and do all of the fetching and updating scans and fields - with scans store (scans list and pagination), fields store (scanned fields of selected scan) and form types store (receiving server errors).
 * 
 * Logis is splitted among three child components:
 * - SearchForm
 * - ScansTable
 * - FieldsTable
 * 
 * @module FindScans
 */
function FindScans() {
  const [api, contextHolder] = notification.useNotification();

  const [collapseKey, setCollapseKey] = useState<number>(1);
  const [collapseFieldsTableKey, setCollapseFieldsTableKey] = useState<number>(0);

  const [selectedScan, setSelectedScan] = useState<SearchScansResponseData>();
  const [selectedScanUpdateTime, setSelectedScanUpdateTime] = useState<number>(0);
  // const [nextScan, setNextScan] = useState<SearchScansResponseData>();

  const [showUndefinedModal, setShowUndefinedModal] = useState<boolean>(false);

  const [hardLock, setHardLock] = useState<boolean>(false);
  const [buttonsLock, setButtonsLock] = useState<boolean>(false);

  const dispatch = useDispatch<AppDispatch>();

  const scansStoreList = useSelector((state: RootState) => state.scans.list);
  const lastEvaluatedKey = useSelector((state: RootState) => state.scans.lastEvaluatedKey);
  const itemsPerPage = useSelector((state: RootState) => state.scans.paginationDetails.itemsPerPage);
  const limit = useSelector((state: RootState) => state.scans.paginationDetails.limit);
  const lastPageNo = useSelector((state: RootState) => state.scans.paginationDetails.pages);
  const scansStoreStatus = useSelector((state: RootState) => state.scans.status);
  const fullFormLoadingStatus = useSelector((state: RootState) => state.fields.fullFormLoading);
  const fieldsStoreStatus = useSelector((state: RootState) => state.fields.status);
  const scansStoreStatusAction = useSelector((state: RootState) => state.scans.statusAction);
  const scansStoreError = useSelector((state: RootState) => state.scans.error);
  const scansStoreRevision = useSelector((state: RootState) => state.scans.revision);
  const scansStoreReducerAction = useSelector((state: RootState) => state.scans.reducerAction);

  const fieldsStoreList = useSelector((state: RootState) => state.fields.list);
  const fieldsStoreError = useSelector((state: RootState) => state.fields.error);
  const scansStoreErrorResults = useSelector((state: RootState) => state.scans.errorResults);

  // const totalPages = useSelector((state: RootState) => state.scans.totalPages);
  const undefinedFormTemplate = LOCALIZATION.UNDEFINED_FORM_TEMPLATE_KEY;

  const tempArr = fieldsStoreList?.map(i => (i.pageY || 1));
  const tempHeight = Math.min(...tempArr) || 1;

  const formTypesStoreError = useSelector((state: RootState) => state.formTypes.error);

  const scanFileImage = useSelector((state: RootState) => state.scans.scanPresignedURL);
  const scanFileFullPdfSrc = useSelector((state: RootState) => state.scans.pdfPresignedURL);

  const [boundingBoxes, setBoundingBoxes] = useState<Record[]>([]);
  const [currentBoundingBox] = useState({});

  const [notificationMessage, setNotificationMessage] = useState<string>('');

  const [searchValues, setSearchValues] = useState<any>();
  const [lastPaginationKeyToUse, setLastPaginationKeyToUse] = useState<any>();

  const valueInputsRef = useRef<any>({});

  const { unsavedChanges, markSavedChanges, setTriggerCallback, changeTrigger, setCleanUpCallback } = useContext(UnsavedChangesContext)
  
  const boundingBoxRef = useRef<HTMLDivElement>(null as unknown as HTMLDivElement);

  const [scansTableCurrentPage, setScansTableCurrentPage] = useState<number>(1);

  const { isAccessDenied } = useContext(AccessDeniedContext);
  const [open, setOpen] = useState(false);
  const [dataLoadedPagesCount, setDataLoadedPagesCount] = useState(limit / itemsPerPage);
  const [sortObj, setSortObj] = useState<{ sortBy: string; sortOrder: string }>({ sortBy: "", sortOrder: "DSC" });
  const [sorting, setSorting] = useState({ field: "", order: "" });
  const [highlightSubmission, setHighlightSubmission] = useState(false);
  const [showAccIdSkipValidationModal, setShowAccIdSkipValidationModal] = useState(false);
  const [showDeleteFormsModal, setShowDeleteFormsModal] = useState(false);
  const [selectedRowsToDelete, setSelectedRowsToDelete] = useState<any[]>([]);

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight
  });
  
  const [modalBody, setModalBody] = useState({ width: (window.innerWidth * 0.45) - 50, height: (window.innerHeight * 0.6) - 30 });

  const setModalSize = (width: any, height: any) => {
    setModalBody({ width: width - 50, height: height - 30 });
  }
  
  const resetSort = () => {
    setSortObj({ sortBy: "", sortOrder: "DSC" });
    setSorting({ field: "", order: "" });
    dispatch(setSorter({ columnKey: "", order: "" }));
  }
 
  /**
   * Updates screen dimension caught in "resize" event listener
   */
  const setDimension = () => {
    getDimension({
      dynamicWidth: window.innerWidth,
      dynamicHeight: window.innerHeight
    })
  }

  /**
   * Inits event emitter "findScansCleanUp"
   * Used to promote trigger to clean up state of child components
   */
  const cleanUpEvent = new Event('findScansCleanUp');

  /**
   * Clean up method
   * Destroys all notifications, unselect scan and reset state of scans store and field store
   * Dispatches clean up event for child components
   */
  const cleanUp = () => {
    notification.destroy();

    setCollapseKey(() => 1);
    setSelectedScan(() => null as unknown as SearchScansResponseData);
    setSelectedScanUpdateTime(() => 0);
    // setNextScan(() => null as unknown as SearchScansResponseData);
    setDataLoadedPagesCount(() => limit / itemsPerPage);
    resetSort();

    dispatch(resetScansStore());
    dispatch(resetFields());
    dispatch(setFindScansCurrentPage(1));

    document.dispatchEvent(cleanUpEvent);
  }

  /**
   * Handle confirmation of search scan form
   * Will check scan dates and if not set, sets them from default values
   * Pagination from DynamoDB is not currently used, as lambda collects all pages in table
   * 
   * @param values Values from search form
   * @param paginationKeyToUse Pagination key from DynamoDb
   */
  const handleSearchScansFormFinish = (values: any, paginationKeyToUse: SearchScansPaginationKey = '' as unknown as SearchScansPaginationKey) => {
    setSelectedScan(() => null as unknown as SearchScansResponseData);
    // setNextScan(() => null as unknown as SearchScansResponseData);
    setSelectedScanUpdateTime(() => 0);
    setScansTableCurrentPage(() => 1);
    setDataLoadedPagesCount(() => limit / itemsPerPage);
    setButtonsLock(() => false);

    values = {
      ...values,
      scanDateFrom: values.scanDateRange?.startDate || null,
      scanDateTo:  values.scanDateRange?.endDate || null,
      scanDateLowerTreshold: dayjs().subtract(60, 'days').hour(0).minute(0).second(0)
    }
    delete values.scanDateRange;

    if(!values.scanDateFrom || isNaN(values.scanDateFrom)) {
      values = {
        ...values,
        scanDateFrom: values.scanDateLowerTreshold
      }
    } else {
      values = {
        ...values,
        scanDateFrom: values.scanDateFrom.hour(0).minute(0).second(0)
      }
    }

    if(!values.scanDateTo || isNaN(values.scanDateTo)) {
      values = {
        ...values,
        scanDateTo: dayjs().hour(23).minute(59).second(59)
      }
    } else {
      values = {
        ...values,
        scanDateTo: values.scanDateTo.hour(23).minute(59).second(59)
      }
    }

    if (
      values.formStatus.length === 0 &&
      values.selectedTemplateType !== undefinedFormTemplate
    ) {
      values = {
        ...values,
        formStatus: LOCALIZATION.FORM_STATUS_OPTIONS,
      };
    }

    setSearchValues(() => values);
    setLastPaginationKeyToUse(() => paginationKeyToUse);
    setSelectedRowsToDelete([]);
    resetSort();
    dispatch(resetScansStore());
    dispatch(searchScans({
      ...values,
      pagination: {
        // lastPaginationKey: 1,
        limit,
        sortBy: "",
        sortOrder: "DSC"
      }
    }));
    dispatch(resetFields());
  } 

  /**
   * Decide if submit button should be enabled or not
   * The decision is based on 
   *  - hardLock or 
   *  - buttonsLock or 
   *  - length of fields store (needs to have some records) or 
   *  - on fact that all of fields are locked 
   * @returns True or False
   */
  const shouldSubmitButtonBeDisabled = () => hardLock || buttonsLock || fieldsStoreList.length === 0 || fieldsStoreList.some(field => !field.locked) || scansStoreStatus === "loading" || fieldsStoreStatus === "loading";

  /**
   * Handles highlighting of bounding box
   * Will try to pair boundingBoxes with mouse coordinates and if paired, highlight bounding box
   * @param annotation 
   */
  const focusValueInput = (annotation: Annotation) => {
    if(annotation.selection) {
      const { anchorX, anchorY} = annotation.selection;

      const hoveredBoundingBoxes = boundingBoxes
      .filter((boundingBox: Record) => boundingBox.geometry.x <= anchorX)
      .filter((boundingBox: Record) => boundingBox.geometry.x + boundingBox.geometry.width >= anchorX)
      .filter((boundingBox: Record) => boundingBox.geometry.y <= anchorY)
      .filter((boundingBox: Record) => boundingBox.geometry.y + boundingBox.geometry.height >= anchorY)
  
      if(hoveredBoundingBoxes.length > 0) {
        const hoveredBoundingBox = hoveredBoundingBoxes[0];
  
        valueInputsRef.current![hoveredBoundingBox.id].focus({
          cursor: 'all'
        });

        setBoundingBoxes((oldBbs) => oldBbs.map(oldBb => {
          if(oldBb.id === hoveredBoundingBox.id) {
            return {
              ...oldBb,
              isHighlighted: true
            } as Record;
          }
          return {
            ...oldBb,
            isHighlighted: false
          } as Record;  
        }))
      }
    }
  }

  /**
   * Handle click on Complete scan
   */
  const handleSubmitCompleteForm = () => {
    if (showUndefinedModal) setShowUndefinedModal(() => false);
    if (showAccIdSkipValidationModal) setShowAccIdSkipValidationModal(false);

    setNotificationMessage(() => LOCALIZATION.FIND_SCANS_FORM_SUBMIT_OK_MESSAGE);

    dispatch(submitScan({
      submit: true,
      transactionId: selectedScan?.scanId,
      formType: selectedScan?.formType,
      fields: fieldsStoreList,
      previousLastUpdatedDateTimeSec: selectedScanUpdateTime > 0 ? selectedScanUpdateTime : selectedScan?.lastUpdatedDateTimeSec
    }));
  }

  /**
   * Check if there are unreadable inputs and show notification if so
   * Otherwise continue to submit form
   */
  const checkBeforeSubmit = () => {
    if(fieldsStoreList.some((field: any) => field.isUnreadable)) {
      setShowUndefinedModal(() => true);
    } else if (fieldsStoreList.some((field: any) => field.skipValidation)) {
      setShowAccIdSkipValidationModal(true);
    } else {
      handleSubmitCompleteForm();
    }
  }

  /**
   * Handle click on Save for later button
   */
  const handleClickSaveLater = () => {
    setNotificationMessage(() => LOCALIZATION.FIND_SCANS_FORM_SAVE_LATER_OK_MESSAGE);
    markSavedChanges();
    dispatch(saveScan({
      submit: false,
      transactionId: selectedScan?.scanId,
      formType: selectedScan?.formType,
      fields: fieldsStoreList,
      previousLastUpdatedDateTimeSec: selectedScanUpdateTime > 0 ? selectedScanUpdateTime : selectedScan?.lastUpdatedDateTimeSec
    }))
  }

  /**
   * Scroll a bounding box overlay after click on record in fields table
   * @param record Template field record
   */
  const scrollBoundingBox = (record: ScannedFieldResponseData) => {
    boundingBoxRef.current.scrollTo({
      top: record.boundingBox.geometry.y * (record.pageY || 1) * boundingBoxRef.current.scrollHeight,
      left: 0,
      behavior: "smooth"
    })
  }

  /**
   * Fetch fields table row elements and ref
   */
  const fetchFieldsTableRef = () => {
    try {
      // Antd Table does not support ref...
      const fieldsTableRef = document.querySelector('.FieldsTable-table div.ant-table-body') as Element;
      const tableRowsEls = fieldsTableRef.children[0].children[1].children;
      return {fieldsTableRef, tableRowsEls};
    } catch(error) {
      console.warn('Fields table ref not working as expected.', error);
      return {
        fieldsTableRef: null as unknown as Element,
        tableRowsEls: [] as unknown as HTMLCollection
      };
    }
  }
  
  /**
   * Handle scroll to corresponding row in fields table
   * @param record Template field record
   */
  const scrollToFieldRow = (record: ScannedFieldResponseData) => {
    const templateFieldsIndex = fieldsStoreList.findIndex((field: ScannedFieldResponseData) => field.id === record.id)
    try {
      const {fieldsTableRef,tableRowsEls} = fetchFieldsTableRef();
      const tableRows = Array.from(tableRowsEls).filter((value: Element, index: number) => index > 0 && index <= templateFieldsIndex);
      const scrollToHeight = tableRows.reduce((prevHeight: number, element: Element) => prevHeight + element.getBoundingClientRect().height, 0);
      
      fieldsTableRef.scrollTo({
        top: scrollToHeight,
        left: 0,
        behavior: "smooth"
      })

    } catch(error) {
      console.warn('Fields table ref not working as expected.', error);
    }
  }

  /**
   * Handle click on item in bounding box and scroll to corresponding item in fields table according to mouse pointer coordinates
   * @param e MouseEvent
   * @param relativeMousePos Coordinates
   */
  const onBoxSelection = (e: React.MouseEvent, relativeMousePos: {x: number, y: number}) => {
    const { x, y } = relativeMousePos;

    const matchingTemplateFields = fieldsStoreList
    .filter((field: ScannedFieldResponseData) => 
      x >= field.boundingBox.geometry.x * 100 && x <= (field.boundingBox.geometry.x * 100 + field.boundingBox.geometry.width * 100)
    ).filter((field: ScannedFieldResponseData) => 
      y >= field.boundingBox.geometry.y * 100 * (field.pageY || 1) && y <= ((field.boundingBox.geometry.y * 100 * (field.pageY || 1)) + (field.boundingBox.geometry.height * 100 * ((field.pageY === 1 || !field.pageY) ? 1 : tempHeight)))
    )
    if(matchingTemplateFields.length > 0) {
      const templateFieldToHighlight = matchingTemplateFields[0];
      dispatch(highlightField(templateFieldToHighlight));
      scrollToFieldRow(templateFieldToHighlight)
    }
  }

  /**
   * Decide which collapsible icon should be returned, according to state of collapsable container
   * @param isActive If active, collapsable container is wide open
   * @returns React.Component
   */
  const returnCollapsibleIcon = (isActive: boolean | undefined) => {
    // the orientation of arrows according to Zeplin https://app.zeplin.io/project/646f118977bb7c17ed063c95/screen/64701c86c1f29022e4a52228
    if(isActive) {
      return <DownOutlined/>;
    }
    return <UpOutlined/>;
  }

  /**
   * Prepare value for y scroll parameter in FieldsTable
   * @returns String
   */
  const getStretchHeightToValue = () => {
    if(collapseKey === 0) {
      return 'calc(100vh - 207px)';
    } 

    if(screenSize.dynamicWidth > 1474 ) return 'calc(50vh - 175px)';
    return 'calc(50vh - 175px)';    
  }
   
  const generateReports = (values: any) => {
    api.info({
      message: LOCALIZATION.FIND_SCANS_REPORT_TITLE,
      description: LOCALIZATION.FIND_SCANS_REPORT_STARTS_MESSAGE,
      placement: 'bottomLeft',
      icon: <Spin />,
    });
    scansApiService
      .postReport(values)
      .then(() => {
        api.destroy();
        api.success({
          message: LOCALIZATION.FIND_SCANS_REPORT_TITLE,
          description: LOCALIZATION.FIND_SCANS_REPORT_DONE_MESSAGE,
          placement: 'bottomLeft',
        });
      })
      .catch((errorMessage) => {
        api.destroy();
        api.error({
          message: LOCALIZATION.FIND_SCANS_REPORT_TITLE,
          description:
            LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(
              ':ERROR',
              errorMessage as string
            ),
          placement: 'bottomLeft',
        });
      });
  }

  const onClickScanRecord = (record: SearchScansResponseData) => {
    dispatch(setScanPresignedURL(''));
    dispatch(setDisableAccValidation(false));
    // setNextScan(() => null as unknown as SearchScansResponseData);
    setButtonsLock(() => false);
    setHardLock(
      () =>
        record.isSubmitted ||
        !record.isSetForManualReview
    );
    setSelectedScan(() => record);
    setSelectedScanUpdateTime(
      () => record.lastUpdatedDateTimeSec
    );
    if (record.formType !== undefinedFormTemplate) {
      dispatch(setFullPdfPresignedURL(''));
      dispatch(
        fetchFieldsByScanId({
          formType: record.formType,
          id: record.id,
        })
      );
    } else if (record.formType === undefinedFormTemplate && record.id !== selectedScan?.id) {
      dispatch(setFullPdfPresignedURL(''));
      const careferaFormType = record.scanId?.split("_")?.[1];
      const isCareFeraForm = (careferaFormType === "LOWINCOMERECERT" || careferaFormType === "LOWINCOMERECERTSPANISH") && record.systemStatus === "Incorrect CARE FERA Scan";
      dispatch(
        fetchFullFormByScanId({
          formType: isCareFeraForm ? careferaFormType : record.formType,
          id: record.id,
        })
      );
    }
    setCollapseFieldsTableKey(() => 1);
  }
  
  const transformFileName = (text: any) => text?.split("-")[0];

  /**
   * At the beginning, do a clean up and send reference to cleanUp method to UnsavedChanges context
   */
  useEffect(() => {
    cleanUp();

    setCleanUpCallback(cleanUp);
  }, []);

  /**
   * Watch for successful status of saveScan or submitScan thunk in scans store
   * Set success notification message or do other cleaning steps
   */
  // useEffect(() => {
  //   if(scansStoreStatus === 'completed') {
  //     if(scansStoreStatusAction === 'saveScan') {
  //       setNotificationMessage(() => LOCALIZATION.FIND_SCANS_FORM_SAVE_LATER_OK_MESSAGE);
  //       markSavedChanges();
  //     }
  
  //     if(scansStoreStatusAction === 'submitScan') {
  //       setNotificationMessage(() => LOCALIZATION.FIND_SCANS_FORM_SUBMIT_OK_MESSAGE);
  //       markSavedChanges();
  //       setHardLock(true);
  //       handleSearchScansFormFinish(searchValues);
  //     }  
  //   }
  // }, [scansStoreStatus, scansStoreStatusAction])

  /**
   * Watch for every change in scans store (thus revision)
   * And show notification messages and etc 
   */
  useEffect(() => {
    if(scansStoreStatus === 'completed' && notificationMessage.length > 0) {
      if(scansStoreStatusAction === 'saveScan' || scansStoreStatusAction === 'submitScan') {
        api.destroy();
        api.success({
          message: scansStoreStatusAction === 'saveScan' ? LOCALIZATION.FIND_SCANS_FORM_CHANGES_SAVE_TITLE : LOCALIZATION.FIND_SCANS_FORM_SUBMIT_INITIATE_TITLE,
          description: scansStoreStatusAction === 'saveScan' ? LOCALIZATION.FIND_SCANS_FORM_SAVE_LATER_OK_MESSAGE : LOCALIZATION.FIND_SCANS_FORM_SUBMIT_OK_MESSAGE,
          placement: 'bottomLeft'
        });
        setNotificationMessage(() => '');
      }
    }
    if(scansStoreStatus === 'completed' && scansStoreStatusAction === 'saveScan') {
      dispatch(resetFields());
      if (selectedScan) {
        dispatch(
          getUpdatedScan({
            formType: selectedScan?.formType,
            id: selectedScan?.id,
            formStatus: searchValues.formStatus
          })
        );
      }
      setSelectedScanUpdateTime(() => dayjs().unix());
    }

    if(scansStoreStatus === 'completed' && scansStoreStatusAction === 'submitScan') {
      markSavedChanges();
      setHardLock(true);
      if (selectedScan) {
        setTimeout(() => {
          dispatch(
            getUpdatedScan({
              formType: selectedScan?.formType,
              id: selectedScan?.id,
              formStatus: searchValues.formStatus
            })
          );
        }, 1500);
      }
      setCollapseKey(() => 1);
      dispatch(resetFields());
      // const selectedScanIndex = scansStoreList.findIndex(i => i.id === selectedScan?.id) + 1;
      // if (selectedScanIndex && selectedScanIndex < scansStoreList.length) setNextScan(scansStoreList[selectedScanIndex]);
      setSelectedScan(() => null as unknown as SearchScansResponseData);
      setSelectedScanUpdateTime(() => 0);
    }

    if(scansStoreReducerAction === 'updateAlreadySubmittedScan') {
      dispatch(resetReducerAction());
      setSelectedScan(() => scansStoreList.find((scan: SearchScansResponseData) => scan.id === selectedScan?.id))
    }
    else if (scansStoreStatus === 'completed' && scansStoreStatusAction === 'deleteScans') {
      setShowDeleteFormsModal(false);
      setSelectedRowsToDelete([]);
      setSelectedScan(() => null as unknown as SearchScansResponseData);
      setScansTableCurrentPage(() => 1);
      dispatch(resetList());
      setDataLoadedPagesCount(() => limit / itemsPerPage);
      dispatch(searchScans({
        ...searchValues,
        pagination: {
          limit,
          sortBy: sortObj.sortBy,
          sortOrder: sortObj.sortOrder
        }
      }));
      api.destroy();
      api.success({
        message: LOCALIZATION.FIND_SCANS_DELETE_FORMS,
        description: `${selectedRowsToDelete.length > 1 ? 'Forms have' : 'Form has'} ${LOCALIZATION.FIND_SCANS_DELETE_FORMS_MESSAGE}`,
        placement: 'bottomLeft',
      });
    }
  }, [scansStoreRevision])

  useEffect(() => {
    if(scansStoreStatus === 'completed') {
      const lastPageElem = document.getElementsByClassName(`ant-pagination-item-${lastPageNo}`)[0];
      const checkLastPageInRange = (dataLoadedPagesCount + (limit / itemsPerPage)) >= lastPageNo;
      if (lastPageElem) {
        if (!checkLastPageInRange) lastPageElem.classList.add('page-disabled');
        else lastPageElem.classList.remove('page-disabled');
      }
    }
  }, [scansStoreStatus])

  useEffect(() => {
    if (scansStoreStatusAction === 'scanNotInList' && selectedScan) setSelectedScan(() => null as unknown as SearchScansResponseData);
  }, [scansStoreStatusAction]);

  /**
   * Handles error states coming from scans store and fields store async thunks
   */
  useEffect(() => {
    if(isAccessDenied) {
      return;
    }
    
    const errorMessage = scansStoreError || fieldsStoreError || formTypesStoreError;
    if(errorMessage?.length === 0 || errorMessage === null) {
      return;
    }

    if((scansStoreStatus === 'warning' || scansStoreStatus === 422) && (scansStoreError || "").length > 0) {
      api.destroy();
      api.error({
        message: scansStoreStatusAction === 'submitScan' ? LOCALIZATION.ERROR_CONFLICT_OCCURED_SUBMIT_TITLE : LOCALIZATION.ERROR_CONFLICT_OCCURED_SAVE_TITLE,
        description: errorMessage as string,
        placement: 'bottomLeft'
      });        
      setNotificationMessage(() => '');
      if (scansStoreStatus === 'warning') setButtonsLock(() => true);
      else if (scansStoreStatus === 422 && scansStoreErrorResults?.length > 0)  {
        const errorFields = scansStoreErrorResults.filter((i: any) => i.message?.length > 0);
        dispatch(setErrorFields(errorFields));
      }
    } else {
      api.error({
        message: LOCALIZATION.ERROR_OCCURED_TITLE,
        description: LOCALIZATION.ERROR_OCCURED_HTTP_MESSAGE.replace(':ERROR', errorMessage as string),
        placement: 'bottomLeft'
      });        
      setNotificationMessage(() => '');
      setOpen(false);
    }
    
    dispatch(resetScansError());
    dispatch(resetFieldsError());
    dispatch(resetFormTypesError());
  }, [scansStoreError, fieldsStoreError, formTypesStoreError])
  
  /**
   * Reacts to changes in fieldsStoreList
   * Refreshes scan last update time for concurrent situations
   * Reloads bounding boxes
   */
  useEffect(() => {
    const refreshedScan = scansStoreList.find((scan: SearchScansResponseData) => scan.id === selectedScan?.id);
    if(refreshedScan) {
      setSelectedScanUpdateTime(() => refreshedScan.lastUpdatedDateTimeSec);
    }

    setBoundingBoxes(() => fieldsStoreList.map(field => ({
      ...field.boundingBox,
      message: field.message,
      locked: field.locked
    } as Record)))
  }, [fieldsStoreList]);

  // useEffect(() => {
  //   if (nextScan) onClickScanRecord(nextScan);
  // }, [scansStoreList]);
  /**
   * Reacts to resizing of window
   */
  useEffect(() => {
    window.addEventListener('resize', setDimension);
     
    return(() => {
      window.removeEventListener('resize', setDimension);
    })
  }, [screenSize])

  /**
   * Reacts to change of selected scan
   * Use to set hard lock if selected scan was already submitted
   */
  useEffect(() => {
    if(selectedScan?.isSetForManualReview === false || selectedScan?.isSubmitted === true) {
      setHardLock(() => true);
    }
  }, [selectedScan])

  useEffect(() => {
    if (scansTableCurrentPage >= dataLoadedPagesCount && lastEvaluatedKey) {
      dispatch(searchScans({
        ...searchValues,
        pagination: {
          lastEvaluatedKey,
          limit,
          sortBy: sortObj.sortBy,
          sortOrder: sortObj.sortOrder
        }
      }));
      setDataLoadedPagesCount((prev) => prev + (limit / itemsPerPage));
    }
  }, [scansTableCurrentPage]);

  const onCancel = () => {
    setOpen(false);
  }

  const deleteForms = () => {
    const deletedScansDetails: DeleteScansParams = {
      selectedTemplateType: selectedScan?.formType || "",
      transactionIds: selectedRowsToDelete?.map(i => i.id),
    }
    dispatch(deleteScans(deletedScansDetails));
  }

  const useKeyDown = (callback: () => void, keys: any[]) => {
    const onKeyDown = (event: any) => {
      const wasAnyKeyPressedPlusCtrl = keys.some((key) => event.key === key && event.ctrlKey);
      const wasAnyKeyPressedPlusCtrlShift = keys.some((key) => event.key === key && event.ctrlKey && event.shiftKey);
      if (wasAnyKeyPressedPlusCtrl || wasAnyKeyPressedPlusCtrlShift) {
        event.preventDefault();
        callback();
      }
    };
    useEffect(() => {
      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }, [onKeyDown]);
  };

  useKeyDown(() => {
    setCollapseKey((prevCollapseKey) => prevCollapseKey === 1 ? 0 : 1);
  }, ["f", "F"]);
  
  useKeyDown(() => {
    if (selectedScan && !shouldSubmitButtonBeDisabled()) {
      setHighlightSubmission(true);
      checkBeforeSubmit();
      setTimeout(() => {
        setHighlightSubmission(false);
      }, 800);
    }
  }, ["d", "D"]);

  const handleScansTableChange = (sorter: any) => {
    if (sorter && sorter.field && sorter.order !== false) {
      const newSorting = {
        field: sorter.field,
        order: sorter.order,
      };
      // Only update sorting state if either sortBy or sortOrder changes
      if (newSorting.field !== sorting.field || newSorting.order !== sorting.order) {
        setSorting(newSorting);
        const sortBy = (sorter.column && sorter.field === "sourceFileObjectPath") ? "TransactionID" : "";
        const sortOrder = (sorter.column && sorter.order === "ascend") ? "ASC" : "DSC";
        setSortObj({ sortBy, sortOrder });
        if (searchValues) {
          setScansTableCurrentPage(() => 1);
          dispatch(resetList());
          setDataLoadedPagesCount(() => limit / itemsPerPage);
          setSelectedScan(() => null as unknown as SearchScansResponseData);
          dispatch(searchScans({
            ...searchValues,
            pagination: {
              limit,
              sortBy,
              sortOrder,
            }
          }));
        }
      }
    }
  };

  return (
    <PageContainer>
      <Col span={24}>
        <Row>
          <Col span={19} style={{ width: '100%', padding: '0 48px' }}>
            <h1
              className="page-title"
              style={{
                fontSize: '24px',
                fontFamily: 'Interstate',
                fontWeight: 'bold',
              }}
            >
              Find Scans
            </h1>
          </Col>
          <Col
            span={5}
            style={{
              paddingTop: '40px',
              paddingRight: '48px',
              textAlign: 'right',
            }}
          >
            <ReportDropdown onItemClick={generateReports} />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <StyledCollapse
              collapsible="icon"
              defaultActiveKey={['1']}
              activeKey={collapseKey}
              onChange={() =>
                setCollapseKey((prevCollapseKey) => {
                  if (prevCollapseKey === 1) {
                    return 0;
                  }
                  return 1;
                })
              }
              // expandIcon={(panelProps => returnCollapsibleIcon(panelProps.isActive))}
            >
              <HeaderPanel
                header={LOCALIZATION.FILTER_SCANS_TITLE}
                key="1"
                className="filter-scan-header"
              >
                <Row style={selectedScan ? { overflowY: 'auto' } : {}}>
                  <Col span={24}>
                    <Row>
                      <Col span={24}>
                        <SearchForm
                          onFormSubmit={(values: any) => {
                            if (unsavedChanges) {
                              setTriggerCallback(() => {
                                handleSearchScansFormFinish(values);
                              });
                              changeTrigger();
                            } else {
                              handleSearchScansFormFinish(values);
                            }
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col span={24}>
                        <ScansTable
                          onRowClick={(record: SearchScansResponseData) => onClickScanRecord(record)}
                          style={{}}
                          rowToSelect={selectedScan as SearchScansResponseData}
                          defaultPage={scansTableCurrentPage}
                          updateDefaultPage={(page: number) => {
                            setScansTableCurrentPage(() => page);
                          }}
                          handleScansTableChange={handleScansTableChange}
                          selectedRowsToDelete={selectedRowsToDelete}
                          deleteForms={(selectedRows) => { setShowDeleteFormsModal(true); setSelectedRowsToDelete(selectedRows) }}
                        />
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </HeaderPanel>
            </StyledCollapse>
          </Col>
        </Row>
        <StyledCollapse
          collapsible="icon"
          defaultActiveKey={['1']}
          activeKey={collapseFieldsTableKey}
          onChange={() =>
            setCollapseFieldsTableKey((prevCollapseKey) => {
              if (prevCollapseKey === 1) {
                return 0;
              }
              return 1;
            })
          }
          // expandIcon={(panelProps => returnCollapsibleIcon(panelProps.isActive))}
          isSecondary
          style={{
            display: selectedScan ? 'flex' : 'none',
            marginTop: selectedScan ? '1rem' : '0',
          }}
        >
          <HeaderPanel
            header={selectedScan ? `Scan: ${selectedScan?.scanId}` : ''}
            key="1"
            className="scan-header"
          >
            {scanFileImage?.length > 0 && (
              <Row style={{ display: 'flex', justifyContent: 'right' }}>
                <Button
                  size="middle"
                  onClick={() => {
                    setOpen(true);
                    if (!scanFileFullPdfSrc)
                      dispatch(
                        fetchFullFormByScanId({
                          formType: selectedScan?.formType || '',
                          id: selectedScan?.id || '',
                        })
                      );
                  }}
                >
                  Click here to view the full form
                </Button>
                <CustomModal
                  title={selectedScan?.formType || ''}
                  className="full-view-modal"
                  open={open}
                  onCancel={onCancel}
                  setModalSize={setModalSize}
                >
                  {fullFormLoadingStatus === 'loading' &&
                  !scanFileFullPdfSrc ? (
                    <Spin className="loading-spin" />
                  ) : (
                    <embed
                      src={scanFileFullPdfSrc}
                      style={{
                        width: modalBody?.width,
                        height: modalBody?.height,
                      }}
                      type="application/pdf"
                    />
                  )}
                </CustomModal>
              </Row>
            )}
            {selectedScan && <Row
              style={{
                display: selectedScan ? 'flex' : 'none',
                paddingBottom: '30px',
              }}
            >
              {selectedScan?.formType === undefinedFormTemplate ? <Col span={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Space>
                  <Typography
                    style={{
                      color: 'var(--color-neutral-05)',
                      fontSize: '14px',
                      margin: '60px 0',
                    }}
                  >
                    {LOCALIZATION.FIND_SCANS_NO_ACTIVE_FLOW_TEXT}
                  </Typography>
                </Space>
              </Col> :
                <Col
                  span={12}
                  style={
                    selectedScan?.formType === undefinedFormTemplate
                      ? { display: 'none' }
                      : {}
                  }
                >
                  <Row>
                    <FieldsTable
                      onFieldRowClick={(record: ScannedFieldResponseData) => {
                        scrollBoundingBox(record);
                      }}
                      stretchHeightTo={getStretchHeightToValue()}
                      hardLock={hardLock}
                      setHardLock={setHardLock}
                      scanWasSubmitted={selectedScan?.isSubmitted || false}
                      selectedScan={selectedScan}
                    />
                  </Row>
                  <Row
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      zIndex: '100',
                      marginTop: '2.5rem',
                    }}
                  >
                    <Button
                      type="default"
                      size="large"
                      disabled={!unsavedChanges || buttonsLock || scansStoreStatus === "loading"}
                      onClick={() => handleClickSaveLater()}
                    >
                      {LOCALIZATION.SAVE_CHANGES_BUTTON_TITLE}
                    </Button>
                    <Button
                      type="primary"
                      size="large"
                      disabled={shouldSubmitButtonBeDisabled()}
                      onClick={() => checkBeforeSubmit()}
                      style={{ marginLeft: '15px', opacity: highlightSubmission ? 0.6 : 1 }}
                    >
                      {LOCALIZATION.SUBMIT_SCANS_BUTTON_TITLE}
                    </Button>
                  </Row>
                </Col>}
              <Col
                span={12}
                style={
                  collapseKey === 0
                    ? { height: 'calc(100vh - 100px)', overflowY: selectedScan?.formType === undefinedFormTemplate ? 'hidden' : 'auto' }
                    : { height: 'calc(50vh - 40px)', overflowY: selectedScan?.formType === undefinedFormTemplate ? 'hidden' : 'auto' }
                }
                ref={boundingBoxRef}
              >
                {(scanFileImage?.length === 0 && selectedScan?.formType !== undefinedFormTemplate) || (selectedScan?.formType === undefinedFormTemplate && !scanFileFullPdfSrc) && (
                  <Space
                    style={{
                      justifyContent: 'center',
                      height: 'calc(50vh - 40px)',
                      width: '100%',
                    }}
                  >
                    <Spin />
                  </Space>
                )}
                {
                  (selectedScan?.formType === undefinedFormTemplate && scanFileFullPdfSrc) &&
                  <embed
                    src={scanFileFullPdfSrc}
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                    type="application/pdf"
                  />
                }
                {scanFileImage?.length > 0 && (
                  <div className="boundingbox-wrapper">
                    <BoundingBoxOverlay
                      id="FindScans_BoundingBoxOverlay"
                      src={scanFileImage}
                      alt="Image was not found"
                      annotations={fieldsStoreList.map(
                        (field: ScannedFieldResponseData) => ({
                          ...field.boundingBox,
                          hasIssue:
                            (field.message || '').length > 0 || !field.locked,
                          geometry: {
                            height: field.boundingBox
                              ? field.boundingBox.geometry.height *
                              100 *
                              (field.pageY === 1 || !field.pageY
                                ? 1
                                : tempHeight)
                              : 0,
                            width: field.boundingBox
                              ? field.boundingBox.geometry.width * 100
                              : 0,
                            x: field.boundingBox
                              ? field.boundingBox.geometry.x * 100
                              : 0,
                            y: field.boundingBox
                              ? field.boundingBox.geometry.y *
                              100 *
                              (field.pageY || 1)
                              : 0,
                          },
                        })
                      )}
                      value={currentBoundingBox}
                      allowTouch={false}
                      hideNonIssues
                      onChange={(annotation: Annotation) =>
                        focusValueInput(annotation)
                      }
                      onClick={onBoxSelection}
                    />
                  </div>
                )}
              </Col>
            </Row>}
          </HeaderPanel>
        </StyledCollapse>
        {createPortal(
          <Modal
            centered
            title={LOCALIZATION.FIND_SCANS_UNDEFINED_MODAL_TITLE}
            open={showUndefinedModal}
            onOk={() => handleSubmitCompleteForm()}
            okText={LOCALIZATION.SUBMIT_FORM_BUTTON_TITLE}
            onCancel={() => setShowUndefinedModal(() => false)}
            cancelText={LOCALIZATION.CANCEL_BUTTON_TITLE}
          >
            <Space style={{ padding: '30px 0' }}>
              <Typography.Text>
                {LOCALIZATION.FIND_SCANS_UNDEFINED_MODAL_MESSAGE}
              </Typography.Text>
            </Space>
          </Modal>,
          document.getElementById('modals') as HTMLElement
        )}
        {createPortal(
          <Modal
            centered
            title={LOCALIZATION.FIND_SCANS_UNDEFINED_MODAL_TITLE}
            open={showAccIdSkipValidationModal}
            onOk={() => handleSubmitCompleteForm()}
            okText={LOCALIZATION.SUBMIT_FORM_BUTTON_TITLE}
            onCancel={() => setShowAccIdSkipValidationModal(() => false)}
            cancelText={LOCALIZATION.CANCEL_BUTTON_TITLE}
          >
            <Space style={{ padding: '30px 0' }}>
              <Typography.Text>
                {LOCALIZATION.FIND_SCANS_ACC_ID_SKIP_VALIDATION_MODAL_MESSAGE}
              </Typography.Text>
            </Space>
          </Modal>,
          document.getElementById('modals') as HTMLElement
        )}
        <div>
          <DraggableDeleteFormsModal
            showDeleteFormsModal={showDeleteFormsModal}
            setShowDeleteFormsModal={setShowDeleteFormsModal}
            deleteForms={deleteForms}
            scansStoreStatus={scansStoreStatus}
            scansStoreStatusAction={scansStoreStatusAction}
            selectedRowsToDelete={selectedRowsToDelete}
          />
        </div>
      </Col>
      {contextHolder}
    </PageContainer>
  );
}

/**
 * 
 */
export default FindScans;