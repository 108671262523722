/* eslint-disable react/require-default-props */
import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'antd';
import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Resizable } from 'react-resizable';
import 'react-resizable/css/styles.css';

interface CustomModalProps {
  title: string;
  className: string;
  open: boolean;
  onCancel: (evt: any) => void;
  children: any;
  setModalSize: (width: any, height: any) => void
}

const CustomModal: React.FC<CustomModalProps> = ({ title, className, open, onCancel, setModalSize, children }) => {
  const [width, setWidth] = useState<any>(window.innerWidth * 0.45);
  const [height, setHeight] = useState<any>(window.innerHeight * 0.6);
  const [disabled, setDisabled] = useState(true);
  const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
  const draggleRef = useRef<HTMLDivElement>(null);

  const onResize = (event: any, { size }: any) => {
    setWidth(size.width);
    setHeight(size.height);
    setModalSize(size.width, size.height);
  };

  const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
    const { clientWidth, clientHeight } = window.document.documentElement;
    const targetRect = draggleRef.current?.getBoundingClientRect();
    if (!targetRect) {
      return;
    }
    setBounds({
      left: -targetRect.left + uiData.x,
      right: clientWidth - (targetRect.right - uiData.x),
      top: -targetRect.top + uiData.y,
      bottom: clientHeight - (targetRect.bottom - uiData.y),
    });
  };

  useEffect(() => {
    setModalSize(width, height);
  }, []);

  return (
    <Resizable
      width={width}
      height={height}
      onResize={onResize}
      axis="both"
      minConstraints={[window.innerWidth * 0.25, window.innerHeight * 0.4]}
      maxConstraints={[window.innerWidth * 0.65, window.innerHeight * 0.8]}
    >
      <Modal
        className={className}
        title={
          <div
            style={{
              width: '100%',
              cursor: 'move',
            }}
            onMouseOver={() => {
              if (disabled) {
                setDisabled(false);
              }
            }}
            onMouseOut={() => {
              setDisabled(true);
            }}
            onFocus={() => { }}
            onBlur={() => { }}
          >
            {title}
          </div>
        }
        maskClosable={false}
        keyboard={false}
        open={open}
        onCancel={onCancel}
        width={width}
        style={{ height }}
        footer={[]}
        // eslint-disable-next-line react/no-unstable-nested-components
        modalRender={(modal) => (
          <Draggable
            disabled={disabled}
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
          >
            <div ref={draggleRef}>{modal}</div>
          </Draggable>
        )}
      >
        {children}
      </Modal>
    </Resizable>
  );
};

export default CustomModal;