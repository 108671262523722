import Draggable, { DraggableData, DraggableEvent } from 'react-draggable';
import { Modal, Spin, Space, Typography, Table } from 'antd';
import { useRef, useState } from 'react';
import dayjs from 'dayjs';
import LOCALIZATION from '../../../localization';

const DraggableDeleteFormsModal = ({
    showDeleteFormsModal,
    setShowDeleteFormsModal,
    deleteForms,
    scansStoreStatus,
    scansStoreStatusAction,
    selectedRowsToDelete,
}: {
    showDeleteFormsModal: boolean;
    setShowDeleteFormsModal: (visible: boolean) => void;
    deleteForms: () => void;
    scansStoreStatus: any;
    scansStoreStatusAction: string;
    selectedRowsToDelete: any[];
}) => {
    const draggleRef = useRef<HTMLDivElement>(null);
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });

    const onStart = (_event: DraggableEvent, uiData: DraggableData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = draggleRef.current?.getBoundingClientRect();
        if (!targetRect) {
            return;
        }
        setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    const renderDraggableModal = (modal: any) => (
        <Draggable
            bounds={bounds}
            nodeRef={draggleRef}
            onStart={(event, uiData) => onStart(event, uiData)}
        >
            <div ref={draggleRef}>{modal}</div>
        </Draggable>
    );
    const transformFileName = (text: any) => text?.split("-")[0];

    return (
        <Modal
            centered
            title={<div
                style={{
                    width: '100%',
                    cursor: 'move',
                }}
                onMouseOver={() => { document.body.style.cursor = 'move' }}
                onMouseOut={() => { document.body.style.cursor = 'default' }}
                onFocus={() => { }}
                onBlur={() => { }}
            >
                {LOCALIZATION.FIND_SCANS_DELETE_FORMS}
            </div>}
            width={800}
            open={showDeleteFormsModal}
            maskClosable={false}
            keyboard={false}
            onOk={deleteForms}
            okText={LOCALIZATION.DELETE_BUTTON_TITLE}
            okButtonProps={{ disabled: scansStoreStatus === 'loading' && scansStoreStatusAction === 'deleteScans' }}
            onCancel={() => setShowDeleteFormsModal(false)}
            cancelText={LOCALIZATION.CANCEL_BUTTON_TITLE}
            cancelButtonProps={{ disabled: scansStoreStatus === 'loading' && scansStoreStatusAction === 'deleteScans' }}
            modalRender={(modal) => renderDraggableModal(modal)}
        >
            <Spin spinning={scansStoreStatus === 'loading' && scansStoreStatusAction === 'deleteScans'}>
                <Space style={{ width: '100%' }} direction="vertical">
                    <Typography.Text>
                        Are you sure you want to delete the selected {selectedRowsToDelete.length > 1 ? `${selectedRowsToDelete.length} forms` : 'form'}?
                    </Typography.Text>
                    <Table
                        className="delete-scans-table"
                        dataSource={selectedRowsToDelete}
                        scroll={{ y: 200 }}
                        columns={[
                            {
                                title: 'File Name',
                                dataIndex: 'id',
                                key: 'fileName',
                                render: (id: string) => transformFileName(id),
                                width: '70%',
                            },
                            {
                                title: 'Scan Date',
                                dataIndex: 'scanDate',
                                key: 'scanDate',
                                render: (scanDate: string) => dayjs(scanDate).format('MMM DD, YYYY, HH:mm:ss'),
                                width: '30%',
                            },
                        ]}
                        rowKey="id"
                        pagination={false}
                        bordered
                    />
                </Space>
            </Spin>
        </Modal>
    );
};

export default DraggableDeleteFormsModal;